/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";

import UserProfile from "views/UserProfile.js";
import Pilkada from "views/Pilkada";
import UsersItaEsa from "views/UsersItaEsa";
import DashboardUser from "views/DashboardUser";
import Pendukung from "views/Pendukung";
import Pendukung_Desa from "views/Pendukung_Desa";
import Dashb from "./assets/img/dashboard.png";
import Vote from "./assets/img/pilkada.png";
import Pend from "./assets/img/pendukung.png";
import Team from "./assets/img/team.png";
import LogoItaEsa from "./assets/img/itaesa.png";
import DPT from "views/DPT";
import Sampel from "views/Sampel";
import Pilkada_2024 from "views/Pilkada_2024";
import Input_Pilkada_2024 from "views/Input_Pilkada_2024";
import Pilkada_2024_Download from "views/Pilkada_2024_Download";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashb,
    component: Dashboard,
    layout: "/admin",
    sidebar: true,
    user: true,
    untuk: "admin",
  },
  {
    path: "/dashboarduser",
    name: "User",
    rtlName: "لوحة القيادة",
    icon: Dashb,
    component: DashboardUser,
    layout: "/admin",
    sidebar: true,
    user: true,
    untuk: "user",
  },
  {
    path: "/insight-pilkada",
    name: "Insight Pilkada",
    rtlName: "الرموز",
    icon: Vote,
    component: Pilkada,
    layout: "/admin",
    sidebar: true,
    user: true,
    untuk: "admin",
  },
  {
    path: "/pilkada-2024",
    name: "Pilkada 2024",
    rtlName: "الرموز",
    icon: Vote,
    component: Pilkada_2024,
    layout: "/admin",
    sidebar: true,
    user: true,
    untuk: "admin",
  },
  {
    path: "/input-pilkada-2024",
    name: "Input Pilkada 2024",
    rtlName: "الرموز",
    icon: Vote,
    component: Input_Pilkada_2024,
    layout: "/admin",
    sidebar: true,
    user: true,
    untuk: "admin",
  },
  {
    path: "/download-pilkada-2024",
    name: "Download Pilkada 2024",
    rtlName: "الرموز",
    icon: Vote,
    component: Pilkada_2024_Download,
    layout: "/admin",
    sidebar: true,
    user: true,
    untuk: "admin",
  },
  // {
  //   path: "/dpt-terbaru",
  //   name: "DPT Terbaru",
  //   rtlName: "الرموز",
  //   icon: Vote,
  //   component: DPT,
  //   layout: "/admin",
  //   sidebar: true,
  //   user: true,
  //   untuk: "admin",
  // },
  // {
  //   path: "/pendukung",
  //   name: "Pendukung",

  //   icon: Pend,
  //   component: Pendukung,
  //   layout: "/admin",
  //   sidebar: true,
  //   user: true,
  //   untuk: "admin",
  // },
  // {
  //   path: "/survey",
  //   name: "Survey",

  //   icon: Pend,
  //   component: Sampel,
  //   layout: "/admin",
  //   sidebar: true,
  //   user: true,
  //   untuk: "admin",
  // },
  // {
  //   path: "/pendukungku/:desa",
  //   name: "Pendukung desa",

  //   icon: "tim-icons icon-atom",
  //   component: Pendukung_Desa,
  //   layout: "/admin",
  //   sidebar: false,
  //   user: true,
  //   untuk: "admin",
  // },

  {
    path: "/users-itaesa",
    name: "Users Ita Esa",
    rtlName: "ملف تفي للمستخدم",
    icon: Team,
    component: UsersItaEsa,
    layout: "/admin",
    sidebar: true,
    user: true,
    untuk: "admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تفي للمستخدم",
    icon: LogoItaEsa,
    component: UserProfile,
    layout: "/admin",
    sidebar: true,
    user: true,
    untuk: "user",
  },
  {
    path: "/user-profile",
    name: "Admin Profile",
    rtlName: "ملف تفي للمستخدم",
    icon: LogoItaEsa,
    component: UserProfile,
    layout: "/admin",
    sidebar: true,
    user: true,
    untuk: "admin",
  },
];
export default routes;
