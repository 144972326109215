import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Badge,
  CustomInput,
} from "reactstrap";

import axios from "axios";
import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

import { logApp } from "utils/func";
import { refreshToken } from "utils/func";

import { GET_PERDESA } from "utils";
import { useParams } from "react-router-dom";
import { POST_PENDUKUNG } from "utils";
import { formatDate } from "utils/func";
import { GET_SAMPEL } from "utils";

function Sampel() {
  const [accessToken, setAccessToken] = useState("");
  const [currentUser, setCurrentUser] = useState("");

  const history = useHistory();
  useEffect(() => {
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "survey");

        refreshToken(history, setAccessToken, user.uid);
      } else {
        history.push("/login");
      }
    } else {
      logApp(currentUser.uid, "survey");
      refreshToken(history, setAccessToken, currentUser.uid);
    }
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}></div>
  );
}

export default Sampel;
