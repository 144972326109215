import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Badge,
  Progress,
  Label,
} from "reactstrap";

import axios from "axios";

import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";
import { useHistory } from "react-router-dom";

import { toProperCase } from "utils/func";

import { logApp } from "utils/func";

import Map from "../components/am4chartMap/am4chartMap_Pilkada_2024";
import { GET_PILKADA2018 } from "utils";
import Sasando from "../assets/img/Sasando.png";

import None from "../assets/img/4.png";
import Ita_esa from "../assets/img/itaesa.png";

import Lentera from "../assets/img/Lentera.png";
import Lontar from "../assets/img/Lontar.png";
import { Line, Bar } from "react-chartjs-2";
import pilkada2008 from "../assets/jsonData/2008.json";
import pilkada2013 from "../assets/jsonData/2013.json";
import { formatNumberToThousands } from "utils/func";
import { GET_PILKADA2018_TOTAL } from "utils";
import { data } from "jquery";
import { refreshToken } from "utils/func";
import { GET_PILKADA2024_TOTAL } from "utils";
import { GET_PILKADA2024 } from "utils";
import { GET_PILKADA2024_TPS } from "utils";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Pilkada_2024_Download() {
  const [dokumen, setDokumen] = useState("");
  const history = useHistory();

  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [listDesa, setListDesa] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [listCari, setListCari] = useState([]);
  const [pilLabel2008, setLabelPil2008] = useState([]);
  const [pilData2008, setDataPil2008] = useState([]);
  const [pilData20082, setDataPil20082] = useState([]);
  const [pil2013] = useState({
    label: ["Omega", "Pasti", "Sama", "Debes", "Lentera"],
    data: [15458, 9030, 10198, 2848, 29445],
    total: 15458 + 9030 + 10198 + 2848 + 29445,
  });

  const [dataPil2018, setDataPil2018] = useState({
    label: [],
    data: [],
    total: 0,
  });

  const getTotalPilkada2018 = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: GET_PILKADA2024_TOTAL,
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setListDesa([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL
          data1 = response.data.data;

          if (data1.length > 0) {
            let total = 0;
            const label = data1.map((item) => {
              return item.paket;
            });
            const data = data1.map((item) => {
              total = total + item.total;
              return item.total;
            });
            setDataPil2018({ label: label, total: total, data: data });
          } else {
            setListDesa([]);
          }
        }
      })
      .catch((error) => {
        // ERROR
        setListDesa([]);
        alert("ada kesalahan pada server");
      });
  };

  const getListTPS = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: GET_PILKADA2024_TPS,
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR

          setListCari([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL

          data1 = response.data.data;

          if (data1.length > 0) {
            const dataku = remakeData2(data1);

            setListCari(dataku);
          } else {
            setListCari([]);
          }
        }
      })
      .catch((error) => {
        // ERROR

        setListCari([]);
        alert("ada kesalahan pada server");
      });
  };

  const getListDesa = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: "https://itaesa.net/api/pilkada2024",
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setListDesa([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL

          data1 = response.data.data;
          if (data1.length > 0) {
            const dataku = remakeData(data1);
            setListDesa(dataku);
          } else {
            setListDesa([]);
          }
        }
      })
      .catch((error) => {
        // ERROR
        setListDesa([]);
        alert("ada kesalahan pada server");
      });
  };

  const getListKecamatan = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: "https://itaesa.net/api/pilkada2024/kecamatan",
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setListKecamatan([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL

          data1 = response.data.data;
          if (data1.length > 0) {
            const dataku = remakeData(data1);
            setListKecamatan(dataku);
          } else {
            setListKecamatan([]);
          }
        }
      })
      .catch((error) => {
        // ERROR
        setListKecamatan([]);
        alert("ada kesalahan pada server");
      });
  };

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Pilkada");
        refreshToken(history, setAccessToken, user.uid);
      } else {
        history.push("/login");
      }
    } else {
      logApp(currentUser.uid, "Pilkada");
      refreshToken(history, setAccessToken, currentUser.uid);
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      getListDesa();
      getListKecamatan();
      getTotalPilkada2018();
      getListTPS();
    }
  }, [currentUser]);

  const remakeData = (data) => {
    const updatedData = data.map((item) => {
      const totalSuara =
        item.totalIta_esa + item.totalLentera + item.totalLontar_malole;
      const totalPHP =
        item.totalIta_esa +
        item.totalLentera +
        item.totalLontar_malole +
        item.terbakar;

      // Determine the winner by finding the key with the maximum votes
      var votes = {
        Ita_esa: item.totalIta_esa,
        Lentera: item.totalLentera,
        Lontar_malole: item.totalLontar_malole,
      };

      var pemenang = "belum ada";
      if (totalSuara > 0) {
        pemenang = Object.keys(votes).reduce((a, b) =>
          votes[a] > votes[b] ? a : b
        );
      }

      return {
        ...item,
        totalSuara,
        pemenang,
        totalPHP,
      };
    });
    return updatedData;
  };

  const remakeData2 = (data) => {
    const updatedData = data.map((item) => {
      const totalSuara = item.ita_esa + item.lentera + item.lontar_malole;
      const totalPHP =
        item.ita_esa + item.lentera + item.lontar_malole + item.terbakar;

      // Determine the winner by finding the key with the maximum votes
      var votes = {
        Ita_esa: item.ita_esa,
        Lentera: item.lentera,
        Lontar_malole: item.lontar_malole,
      };

      var pemenang = "belum ada";
      if (totalSuara > 0) {
        pemenang = Object.keys(votes).reduce((a, b) =>
          votes[a] > votes[b] ? a : b
        );
      }

      return {
        ...item,
        totalSuara,
        pemenang,
        totalPHP,
      };
    });
    return updatedData;
  };

  const pemenangPilkada = (pemenang) => {
    if (pemenang == "Ita_esa") {
      return Ita_esa;
    }
    if (pemenang == "Lontar_malole") {
      return Lontar;
    }
    if (pemenang == "Lentera") {
      return Lentera;
    }
    if (pemenang == "belum ada") {
      return None;
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">
                  Rekapan Suara Per Kecamatan{" "}
                  <Badge color="success">Pilkada 2024</Badge>
                </CardTitle>
                <Col md={3}>
                  <div>
                    <ReactHTMLTableToExcel
                      className="btn"
                      table="hasil_perKecamatan"
                      filename={"Hasi Kecamatan " + Date()}
                      sheet="Data "
                      buttonText="download"
                    />
                  </div>
                </Col>
              </CardHeader>

              <CardBody>
                <Table
                  className="tablesorter"
                  responsive
                  id="hasil_perKecamatan"
                >
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Kecamatan</th>
                      <th>Jumlah Desa</th>
                      <th>Jumlah TPS</th>
                      <th>TPS masuk</th>

                      <th>Total Pemilih</th>
                      <th>Total PHP</th>
                      <th>Pemenang</th>
                      <th>Total Suara Sah</th>
                      <th>Ita Esa</th>
                      <th>Lontar Malole</th>

                      <th>Lentera</th>
                      <th>Tidak Sah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listKecamatan &&
                      listKecamatan.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{toProperCase(item.kecamatan)}</td>
                          <td>{item.jumlah_desa}</td>
                          <td>{item.jumlah_tps}</td>
                          <td>{item.tps_Input}</td>
                          <td>{item.totalPemilih}</td>
                          <td>{item.totalPHP}</td>

                          <td className="text-center">{item.pemenang}</td>
                          <td>{item.totalSuara}</td>
                          <td>{item.totalIta_esa}</td>
                          <td>{item.totalLontar_malole}</td>

                          <td>{item.totalLentera}</td>
                          <td>{item.terbakar}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">
                  Rekapan Suara Perdesa{" "}
                  <Badge color="success">Pilkada 2024</Badge>
                </CardTitle>
                <Col md={3}>
                  <div>
                    <ReactHTMLTableToExcel
                      className="btn"
                      table="hasil_perDesa"
                      filename={"Hasi Desa " + Date()}
                      sheet="Data "
                      buttonText="download"
                    />
                  </div>
                </Col>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive id="hasil_perDesa">
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Kecamatan</th>
                      <th>Desa</th>
                      <th>Jumlah TPS</th>
                      <th>TPS masuk</th>

                      <th>Total Pemilih</th>
                      <th>Total PHP</th>
                      <th>Pemenang</th>
                      <th>Total Suara Sah</th>
                      <th>Ita Esa</th>
                      <th>Lontar Malole</th>
                      <th>Lentera</th>
                      <th>Tidak Sah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listDesa &&
                      listDesa.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{toProperCase(item.kecamatan)}</td>
                          <td>{toProperCase(item.desa)}</td>
                          <td>{item.jumlah_tps}</td>
                          <td>{item.tps_Input}</td>
                          <td>{item.totalPemilih}</td>
                          <td>{item.totalPHP}</td>

                          <td className="text-center">{item.pemenang}</td>
                          <td>{item.totalSuara}</td>
                          <td>{item.totalIta_esa}</td>
                          <td>{item.totalLontar_malole}</td>

                          <td>{item.totalLentera}</td>
                          <td>{item.terbakar}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">
                  Distribusi Suara Per TPS{" "}
                  <Badge color="success">Pilkada 2024</Badge>
                </CardTitle>

                <Col md={3}>
                  <div>
                    <ReactHTMLTableToExcel
                      className="btn"
                      table="hasil_perTPS"
                      filename={"Hasi TPS " + Date()}
                      sheet="Data "
                      buttonText="download"
                    />
                  </div>
                </Col>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive id="hasil_perTPS">
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Kecamatan</th>
                      <th>Desa</th>
                      <th>TPS</th>

                      <th>Total Pemilih</th>
                      <th>Total PHP</th>
                      <th>Pemenang</th>
                      <th>Total Suara Sah</th>
                      <th>Ita Esa</th>
                      <th>Lontar Malole</th>
                      <th>Lentera</th>
                      <th>Tidak Sah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listCari &&
                      listCari.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{toProperCase(item.kecamatan)}</td>
                          <td>{toProperCase(item.desa)}</td>
                          <td className="text-center" style={{ width: 150 }}>
                            {item.tps}
                          </td>
                          <td>{item.total}</td>
                          <td>{item.totalPHP}</td>

                          <td className="text-center">{item.pemenang}</td>
                          <td>{item.totalSuara}</td>
                          <td>{item.ita_esa}</td>
                          <td>{item.lontar_malole}</td>

                          <td>{item.lentera}</td>
                          <td>{item.terbakar}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Pilkada_2024_Download;
