import React, { useEffect, useState } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import cities from "./mock";

//import roteNdaoGeo from '../../../../assets/jsonData/kab_Rote.json';
import roteNdaoGeo from "../../assets/jsonData/kab_Rote.json";

import s from "./am4chartMap.module.scss";
import "./tooltip.css";
// Fungsi untuk menghasilkan warna acak
import ItaEsa from "../../assets/img/itaesa.png";
import RNB from "../../assets/img/RNB.png";
import Lentera from "../../assets/img/Lentera.png";
import Lontar from "../../assets/img/Lontar.png";

import { Badge, Progress } from "reactstrap";
import { formatNumberToThousands } from "utils/func";

const Am4chartMap_Pilkada_2024 = ({ listHasil }) => {
  const features = roteNdaoGeo.features;
  const [mapCombine, setMapCombine] = useState();
  const [winner, setWinner] = useState({
    totalIta_esaWinners: 0,
    totalLenteraWinners: 0,
    totalLontar_maloleWinners: 0,

    totalIta_esaVotes: 0,
    totalLenteraVotes: 0,
    totalLontar_maloleVotes: 0,

    totalDesa_Ok: 0,
  });

  const pemenangPilkada = (pemenang) => {
    if (pemenang == "Ita_esa") {
      return "#C6E7FF";
    }
    if (pemenang == "Lontar_malole") {
      return "#f05454";
    }
    if (pemenang == "Lentera") {
      return "#3976f7";
    }
    if (pemenang == "belum ada") {
      return "#C7C6C1";
    }
  };
  function countWinnersAndVotesByCategory(data) {
    const results = {
      totalIta_esaWinners: 0,
      totalLenteraWinners: 0,

      totalLontar_maloleWinners: 0,
      totalIta_esaVotes: 0,
      totalLenteraVotes: 0,
      totalLontar_maloleVotes: 0,
      totalDesa_Ok: 0,
    };

    // Iterate over each item to count winners and sum votes
    data.forEach((item) => {
      // Increment the winner counts
      if (item.pemenang === "Ita_esa") results.totalIta_esaWinners += 1;
      if (item.pemenang === "Lentera") results.totalLenteraWinners += 1;
      if (item.pemenang === "Lontar_malole")
        results.totalLontar_maloleWinners += 1;

      if (item.jumlah_tps == item.tps_Input) results.totalDesa_Ok += 1;

      // Sum votes for each category
      results.totalIta_esaVotes += item.totalIta_esa;
      results.totalLenteraVotes += item.totalLentera;
      results.totalLontar_maloleVotes += item.totalLontar_malole;
    });

    return results;
  }

  const combineData = () => {
    var desaku = [];
    var desa;
    for (let i = 0; i < features.length; i++) {
      desa = features[i];

      var pendukungDesa = listHasil.filter((item) => {
        return item.desa.toUpperCase() === desa.properties.DESA.toUpperCase();
      });

      if (pendukungDesa.length > 0) {
        desa.properties.totalPemilih = pendukungDesa[0].totalPemilih;
        desa.properties.totalPhp = pendukungDesa[0].totalPHP;
        desa.properties.totalIta_esa = pendukungDesa[0].totalIta_esa;
        desa.properties.Lontar_malole = pendukungDesa[0].totalLontar_malole;
        desa.properties.totalLentera = pendukungDesa[0].totalLentera;

        desa.properties.pemenang = pendukungDesa[0].pemenang;

        desa.properties.color = pemenangPilkada(pendukungDesa[0].pemenang);
      } else {
        desa.properties.totalPemilih = 0;
        desa.properties.totalPhp = 0;
        desa.properties.totalIta_esa = 0;
        desa.properties.totalLontar_malole = 0;
        desa.properties.totalLentera = 0;

        desa.properties.pemenang = 0;
        desa.properties.color = "#f5ebeb";
      }

      // return [r['desa'], jmlKrr, jmlKrt, jmlKrst, ibuDesa.length, colorHamil];

      desaku.push(desa);
    }

    const datamap = {
      type: "FeatureCollection",
      features: desaku,
    };

    setMapCombine(datamap);
  };

  function getColorByValue(value) {
    return value;
  }

  useEffect(() => {
    if (listHasil) {
      combineData();
      setWinner(countWinnersAndVotesByCategory(listHasil));
    }
  }, [listHasil]);

  useEffect(() => {
    if (mapCombine) {
      let map = am4core.create("map", am4maps.MapChart);
      map.geodata = mapCombine;
      map.percentHeight = 90;
      map.dy = 10;
      //   map.projection = new am4maps.projections.AlbersUsa();
      let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
      map.homeZoomLevel = 1;
      map.zoomControl = new am4maps.ZoomControl();
      map.zoomControl.layout = "horizontal";
      map.zoomControl.align = "left";
      map.zoomControl.valign = "bottom";
      map.zoomControl.dy = 30;
      map.zoomControl.dx = 30;
      map.zoomControl.contentHeight = 20;
      map.zoomControl.minusButton.background.fill = am4core.color("#C7D0FF");
      map.zoomControl.minusButton.background.stroke = am4core.color("white");
      map.zoomControl.minusButton.label.fontWeight = 600;
      map.zoomControl.minusButton.label.fontSize = 22;
      map.zoomControl.minusButton.scale = 0.75;
      map.zoomControl.minusButton.label.scale = 0.75;
      map.zoomControl.plusButton.background.fill = am4core.color("#C7D0FF");
      map.zoomControl.plusButton.background.stroke = am4core.color("#6979C9");
      map.zoomControl.plusButton.label.fontWeight = 600;
      map.zoomControl.plusButton.label.fontSize = 22;
      map.zoomControl.plusButton.label.align = "center";
      map.zoomControl.plusButton.scale = 0.75;
      map.zoomControl.plusButton.label.scale = 0.75;
      map.zoomControl.plusButton.dx = 5;
      let plusButtonHoverState =
        map.zoomControl.plusButton.background.states.create("hover");
      plusButtonHoverState.properties.fill = am4core.color("#354D84");
      let minusButtonHoverState =
        map.zoomControl.minusButton.background.states.create("hover");
      minusButtonHoverState.properties.fill = am4core.color("#354D84");
      let polygonTemplate = polygonSeries.mapPolygons.template;

      polygonTemplate.tooltipHTML = `
    <div className="custom-tooltip">
      <b>{DESA} - {KECAMATAN}</b>
      <br>DPT : {totalPemilih} </b>
      <br>PHP : {totalPhp} </b>
      <br>Ita Esa : {totalIta_esa} </b>
      <br>Lontar Malole : {totalLontar_malole} </b>
      <br>Lentera : {totalLentera} </b>
     
    </div>
    `;

      //

      //polygonTemplate.propertyFields.fill = 'SYMB';
      polygonSeries.events.on("datavalidated", () => {
        polygonSeries.mapPolygons.each((polygon) => {
          let color = polygon.dataItem.dataContext.color;
          polygon.fill = color;
        });
      });

      //

      //polygonTemplate.fill = am4core.color('red');
      polygonTemplate.stroke = am4core.color("white");
      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = am4core.color("blue");

      //

      //

      let citySeries = map.series.push(new am4maps.MapImageSeries());
      citySeries.data = cities;
      citySeries.dataFields.value = "size";
      let city = citySeries.mapImages.template;
      city.nonScaling = true;
      city.propertyFields.latitude = "latitude";
      city.propertyFields.longitude = "longitude";
      let circle = city.createChild(am4core.Circle);
      circle.fill = am4core.color("#C7D0FF");
      circle.strokeWidth = 0;
      let circleHoverState = circle.states.create("hover");
      circleHoverState.properties.strokeWidth = 1;
      circle.tooltipText = "{tooltip}";
      circle.propertyFields.radius = "size";
    }
  }, [mapCombine]);

  return (
    <div className={s.mapChart}>
      <div className={s.stats}>
        <p className="h3 m-0">
          <span className="mr-xs fw-normal">Rekapitulasi Pilkada 2024</span>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: 5,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              width: 40,
              height: 40,
              position: "relative",
              cursor: "pointer",
              marginRight: 10,
            }}
          >
            <img
              src={ItaEsa}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
              }}
            />
          </div>
          <span>Ita Esa</span>
          <div
            style={{
              height: 15,
              width: 30,
              backgroundColor: "#C6E7FF",
              marginLeft: 5,
              marginRight: 5,
              borderRadius: 3,
            }}
          ></div>
          <span>
            {winner && winner.totalIta_esaWinners + " Desa "}{" "}
            <Badge>
              {winner && formatNumberToThousands(winner.totalIta_esaVotes)}
            </Badge>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: 5,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              width: 40,
              height: 40,
              position: "relative",
              cursor: "pointer",
              marginRight: 10,
            }}
          >
            <img
              src={Lontar}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
              }}
            />
          </div>
          <span>Lontar</span>
          <div
            style={{
              height: 15,
              width: 30,
              backgroundColor: "#f05454",
              marginLeft: 5,
              borderRadius: 3,
              marginRight: 5,
            }}
          ></div>{" "}
          <span>
            {winner && winner.totalLontar_maloleWinners + " Desa "}{" "}
            <Badge>
              {winner &&
                formatNumberToThousands(winner.totalLontar_maloleVotes)}
            </Badge>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: 5,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              width: 40,
              height: 40,
              position: "relative",
              cursor: "pointer",
              marginRight: 10,
            }}
          >
            <img
              src={Lentera}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
              }}
            />
          </div>
          <span>Lentera</span>
          <div
            style={{
              height: 15,
              width: 30,
              backgroundColor: "#3976f7",
              marginLeft: 5,
              marginRight: 5,
              borderRadius: 3,
            }}
          ></div>
          <span>
            {winner && winner.totalLenteraWinners + " Desa "}{" "}
            <Badge>
              {winner && formatNumberToThousands(winner.totalLenteraVotes)}
            </Badge>
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: 5,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }} // Memastikan parent container selebar penuh
          >
            <div className="d-flex flex-column mt-3">
              <div>
                <p className="body-2">
                  {" ("}
                  {winner.totalDesa_Ok}
                  {"/119) "}
                  {Math.round((winner.totalDesa_Ok / 119) * 100)}% Desa input{" "}
                </p>
              </div>
              <div style={{ width: "100%" }}>
                {" "}
                {/* Wrapper untuk Progress */}
                <Progress
                  color={
                    (winner.totalDesa_Ok / 119) * 100 === 100
                      ? "success"
                      : "danger"
                  }
                  className="progress-xs"
                  value={(winner.totalDesa_Ok / 119) * 100}
                  style={{ width: "100%" }} // Progress mengikuti lebar penuh
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.map} id="map"></div>
    </div>
  );
};

export default Am4chartMap_Pilkada_2024;
