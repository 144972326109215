import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Badge,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
} from "reactstrap";

import axios from "axios";

import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";
import { useHistory } from "react-router-dom";

import { toProperCase } from "utils/func";

import { logApp } from "utils/func";
import swal from "sweetalert";
import None from "../assets/img/4.png";
import Ita_esa from "../assets/img/itaesa.png";

import Lentera from "../assets/img/Lentera.png";
import Lontar from "../assets/img/Lontar.png";
import { Line, Bar } from "react-chartjs-2";
import pilkada2008 from "../assets/jsonData/2008.json";

import { formatNumberToThousands } from "utils/func";

import { refreshToken } from "utils/func";

import { GET_PILKADA2024_TPS } from "utils";
import { POST_PILKADA2024_TPS } from "utils";

function Input_Pilkada_2024() {
  const [dokumen, setDokumen] = useState("");
  const history = useHistory();
  const [modalOpen2, setModalOpen2] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [listDesa, setListDesa] = useState([]);
  const [pilLabel2008, setLabelPil2008] = useState([]);
  const [pilData2008, setDataPil2008] = useState([]);
  const [pilData20082, setDataPil20082] = useState([]);
  const [pil2013] = useState({
    label: ["Omega", "Pasti", "Sama", "Debes", "Lentera"],
    data: [15458, 9030, 10198, 2848, 29445],
    total: 15458 + 9030 + 10198 + 2848 + 29445,
  });

  const [kecamatan, setKecamatan] = useState("");
  const [desa, setDesa] = useState("");
  const [tps, setTps] = useState("");
  const [idTps, setIdTps] = useState("");

  const [suaraItaEsa, setSuaraItaEsa] = useState(0);
  const [suaraLentera, setSuaraLentera] = useState(0);
  const [suaraLontarMalole, setSuaraLontarMalole] = useState(0);
  const [suaraTerbakar, setSuaraTerbakar] = useState(0);
  const [listCari, setListCari] = useState([]);
  const [tpsTerinput, setTpsTerinput] = useState(0);

  const [cari, setCari] = useState("");

  const onModalClose = () => {
    setKecamatan("");
    setDesa("");
    setTps("");

    setSuaraItaEsa(0);
    setIdTps("");
    setSuaraLontarMalole(0);
    setSuaraLentera(0);
    setSuaraTerbakar(0);

    setModalOpen2(false);
  };

  const handleSubmit = async (event) => {
    if (idTps && currentUser) {
      const formData = new FormData();
      formData.append("photo", dokumen);

      formData.append("id", idTps);
      formData.append("ita_esa", suaraItaEsa);
      formData.append("lontar_malole", suaraLontarMalole);

      formData.append("lentera", suaraLentera);
      formData.append("terbakar", suaraTerbakar);
      formData.append("idInputor", currentUser.uid);

      await axios({
        method: "post",
        url: POST_PILKADA2024_TPS,
        timeout: API_TIMEOUT,
        data: formData,
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            swal("Failed", "ada kesalahan pada server", "error");
          } else {
            //BERHASIL
            swal("Success", "Data berhasil diposting", "success");
            getListDesa();
            onModalClose();
          }
        })
        .catch((error) => {
          // ERROR
          swal("Failed", "Ada kesalahan pada server", "error");
        });

      setSaveLoading(false);
    } else {
      swal("Failed", "Ada data yang belum diinput", "error");
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    if (cari) {
      const filtered = listDesa.filter((item) =>
        item.desa.toLowerCase().includes(cari.toLowerCase())
      );
      setListCari(filtered);
    } else {
      setListCari(listDesa);
    }
  }, [cari]);

  const handleSimpan = () => {
    setSaveLoading(true);
    handleSubmit();
  };

  const getListDesa = async () => {
    var data1 = [];

    await axios({
      method: "get",
      url: GET_PILKADA2024_TPS,
      timeout: API_TIMEOUT,

      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          setListDesa([]);
          setListCari([]);
          alert("ada kesalahan pada server");
        } else {
          //BERHASIL

          data1 = response.data.data;

          if (data1.length > 0) {
            const dataku = remakeData(data1);

            setListDesa(dataku);
            setListCari(dataku);
            setTpsTerinput(hitungTPSInput(dataku));
          } else {
            setListDesa([]);
            setListCari([]);
          }
        }
      })
      .catch((error) => {
        // ERROR
        setListDesa([]);
        setListCari([]);
        alert("ada kesalahan pada server");
      });
  };

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Input Pilkada 2024");
        refreshToken(history, setAccessToken, user.uid);
      } else {
        history.push("/login");
      }
    } else {
      logApp(currentUser.uid, "Pilkada");
      refreshToken(history, setAccessToken, currentUser.uid);
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      const label = pilkada2008["2008"].map((item) => {
        return item.Paket;
      });
      const data1 = pilkada2008["2008"].map((item) => {
        return item.Putaran1;
      });
      const data2 = pilkada2008["2008"].map((item) => {
        return item.Putaran2;
      });

      setLabelPil2008(label);
      setDataPil2008(data1);
      setDataPil20082(data2);
      getListDesa();
    }
  }, [currentUser]);

  const hitungTPSInput = (data) => {
    var totTPSInput = 0;

    data.map((item) => {
      const tot =
        item.ita_esa + item.lentera + item.lontar_malole + item.terbakar;
      if (tot > 0) totTPSInput = totTPSInput + 1;
    });

    return totTPSInput;
  };

  const remakeData = (data) => {
    const updatedData = data.map((item) => {
      const totalSuara = item.ita_esa + item.lentera + item.lontar_malole;
      const totalPHP =
        item.ita_esa + item.lentera + item.lontar_malole + item.terbakar;

      // Determine the winner by finding the key with the maximum votes
      var votes = {
        Ita_esa: item.ita_esa,
        Lentera: item.lentera,
        Lontar_malole: item.lontar_malole,
      };

      var pemenang = "belum ada";
      if (totalSuara > 0) {
        pemenang = Object.keys(votes).reduce((a, b) =>
          votes[a] > votes[b] ? a : b
        );
      }

      return {
        ...item,
        totalSuara,
        pemenang,
        totalPHP,
      };
    });
    return updatedData;
  };

  const pemenangPilkada = (pemenang) => {
    if (pemenang == "Ita_esa") {
      return Ita_esa;
    }
    if (pemenang == "Lontar_malole") {
      return Lontar;
    }
    if (pemenang == "Lentera") {
      return Lentera;
    }
    if (pemenang == "belum ada") {
      return None;
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">
                  Distribusi Suara Per TPS{" "}
                  <Badge color="success">Pilkada 2024</Badge>
                </CardTitle>

                <Col md={6}>
                  <div className="d-flex flex-column mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="body-2 mb-0">
                        {" ("}
                        {tpsTerinput}
                        {"/273) "}
                        {Math.round((tpsTerinput / 273) * 100)}% TPS Yang sudah
                        Masuk{" "}
                      </p>
                    </div>
                    {/* Progress bar */}
                    <div className="w-100 mt-2">
                      <Progress
                        color={
                          (tpsTerinput / 273) * 100 > 80 ? "success" : "danger"
                        }
                        className="progress-xs"
                        value={(tpsTerinput / 273) * 100}
                        style={{ width: "100%" }} // Ensures full width
                      />
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <Label>Ketik Nama Desa Cari</Label>
                  <Input
                    value={cari}
                    onChange={(e) => setCari(e.target.value)}
                    style={{ color: "black" }}
                    type="text"
                  />
                </Col>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Kecamatan</th>
                      <th>Desa</th>
                      <th>TPS</th>

                      <th>Total Pemilih</th>
                      <th>Total PHP</th>
                      <th>Pemenang</th>
                      <th>Ita Esa</th>
                      <th>Lontar Malole</th>

                      <th>Lentera</th>
                      <th>Tidak Sah</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listCari &&
                      listCari.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{toProperCase(item.kecamatan)}</td>
                          <td>{toProperCase(item.desa)}</td>
                          <td className="text-center" style={{ width: 150 }}>
                            {item.tps}
                          </td>
                          <td>{formatNumberToThousands(item.total)}</td>
                          <td>{formatNumberToThousands(item.totalPHP)}</td>

                          <td className="text-center">
                            {
                              <div
                                style={{
                                  width: 40,
                                  height: 40,
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={pemenangPilkada(item.pemenang)}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
                                  }}
                                />
                              </div>
                            }
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.ita_esa}
                                    {") "}
                                    {Math.round(
                                      (item.ita_esa / item.totalSuara) * 100
                                    )}
                                    % suara{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.ita_esa / item.totalSuara) * 100 > 50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={(item.ita_esa / item.totalSuara) * 100}
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.lontar_malole}
                                    {") "}
                                    {Math.round(
                                      (item.lontar_malole / item.totalSuara) *
                                        100
                                    )}
                                    % suara{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.lontar_malole / item.totalSuara) *
                                      100 >
                                    50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={
                                    (item.lontar_malole / item.totalSuara) * 100
                                  }
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.lentera}
                                    {") "}
                                    {Math.round(
                                      (item.lentera / item.totalSuara) * 100
                                    )}
                                    % suara{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.lentera / item.totalSuara) * 100 > 50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={(item.lentera / item.totalSuara) * 100}
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {" ("}
                                    {item.terbakar}
                                    {") "}
                                    {Math.round(
                                      (item.terbakar / item.totalPHP) * 100
                                    )}
                                    % suara{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.terbakar / item.totalPHP) * 100 > 50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={(item.terbakar / item.totalPHP) * 100}
                                />
                              </div>
                            </div>
                          </td>

                          <td className="text-center">
                            <Button
                              color={
                                item.totalSuara > 0 ? "success" : "primary"
                              }
                              size="sm"
                              onClick={() => {
                                setKecamatan(item.kecamatan);
                                setDesa(item.desa);
                                setTps(item.tps);
                                setIdTps(item.id);
                                setSuaraItaEsa(item.ita_esa);
                                setSuaraLontarMalole(item.lontar_malole);
                                setSuaraLentera(item.lentera);
                                setSuaraTerbakar(item.terbakar);
                                setModalOpen2(true);
                              }}
                            >
                              {item.totalSuara > 0 ? "Edit" : "Input"}
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="lg"
          isOpen={modalOpen2}
          toggle={() => setModalOpen2(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen2(false)}>
            <div>
              <div>
                {"Input Data"}
                <Badge color="primary">{kecamatan}</Badge>{" "}
                <Badge color="danger">{desa}</Badge>
                <Badge color="success">{tps}</Badge>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Ita Esa
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="number"
                      name="liga"
                      value={suaraItaEsa}
                      onChange={(event) => {
                        setSuaraItaEsa(event.target.value);
                      }}
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Lontar Malole
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="number"
                      name="liga"
                      value={suaraLontarMalole}
                      onChange={(event) => {
                        setSuaraLontarMalole(event.target.value);
                      }}
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Lentera
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="number"
                      name="liga"
                      value={suaraLentera}
                      onChange={(event) => {
                        setSuaraLentera(event.target.value);
                      }}
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  Tidak Sah
                </Label>
                <Col lg="9">
                  <div>
                    <label></label>
                    <Input
                      style={{ color: "black" }}
                      type="number"
                      name="liga"
                      value={suaraTerbakar}
                      onChange={(event) => {
                        setSuaraTerbakar(event.target.value);
                      }}
                    />
                  </div>
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  onModalClose();
                  setModalOpen2(false);
                }}
              >
                Cancel
              </Button>
              <div style={{ margin: 5 }}></div>
              {saveLoading ? (
                <Button className="btn-rounded" size="sm">
                  {" "}
                  {"Simpan ..."}
                </Button>
              ) : (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={handleSimpan}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Input_Pilkada_2024;
