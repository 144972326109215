import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Progress,
  InputGroup,
  InputGroupAddon,
  Spinner,
  Badge,
} from "reactstrap";

import axios from "axios";
import { useState, useEffect } from "react";
import { API_TIMEOUT } from "utils";

import { USERS } from "utils";

import swal from "sweetalert";
import { logApp } from "utils/func";
import { useHistory } from "react-router-dom";
import { refreshToken } from "utils/func";
import { GET_DESA } from "utils";
import { removeExtraSpaces } from "utils/func";
import { generatePassword } from "utils/func";
import { formatDateTime } from "utils/func";
import AktifitasUser from "components/ListInPUTPendukung/AktifitasUser";
import { formatNumberToThousands } from "utils/func";

function UsersManek() {
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);

  const [modalOpen3, setModalOpen3] = useState(false);

  const [dokumen, setDokumen] = useState("");

  const [status, setStatus] = useState("");
  const [username, setUsername] = useState("");
  const [nama, setNama] = useState("");

  const [url, setUrl] = useState("");
  const [uid, setUid] = useState("");

  const [gambar, setGambar] = useState("");
  const [tambah, setTambah] = useState(true);
  const [searchItem, setSearchItem] = useState("");
  const [desa, setDesa] = useState("");
  const [noHp, setNoHp] = useState("");
  const [password, setPassword] = useState("");
  const [listDesa, setListDesa] = useState([]);

  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [userId, setUserId] = useState("");

  const history = useHistory();

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, "User Ita Esa");
        setCurrentUser(user);
        refreshToken(history, setAccessToken, user.uid);
      } else {
        history.push("/login");
      }
    } else {
      logApp(currentUser.uid, "User Ita Esa");
      refreshToken(history, setAccessToken, currentUser.uid);
    }
  }, []);

  //accessToken
  useEffect(() => {
    if (currentUser) {
      ambilUsers();
      ambilPerangkatDaerah();
    }
    //, accessToken
  }, [currentUser]);

  const handleSimpan = () => {
    if (!nama || !status || !desa || !password || !noHp) {
      swal("Gagal", "Data yang anda masukan harus lengkap", "error");
      return;
    }

    simpanAdmin();
  };

  function kirimPesan(nama, nohp, username, password) {
    //   var url = "https://pati.wablas.com/api/send-message";
    var url = "https://kudus.wablas.com/api/send-message";
    var Tokenwhatsap =
      "cwmRbIhODIa7WcS4M06b8xYRTj8OpPqQXHHjcUjLfWqDecH8eFmHkk98qSGDud9I";

    //082237839018

    const peran = status == 1 ? "Super Admin" : status == 2 ? "Admin" : "User";
    console.log(nama);
    console.log(username);
    console.log(password);
    var pesanKu =
      `Soda Molek To'o/Te'o *` +
      nama +
      "*" +
      `\n\ \n\SALAM ITA ESA` +
      `\n\ \n\Selamat bergabung di aplikasi Ita Esa ` +
      `\n\Username/No HP  : ` +
      nohp +
      `\n\Password  : ` +
      password +
      `\n\Peran  : ` +
      peran +
      `\n\Desa/Kelurahan  : ` +
      desa +
      `\n\Klik Link  : ` +
      "https://itaesa.net" +
      " Untuk mempergunakan aplikasi";

    var data = {
      phone: nohp,
      message:
        pesanKu +
        `\n\ \n\ Bersama Kita Bisa \n\ 💥💥💥 Meledak - Melayani dengan Dengan Kasih`,
    };

    var options_user = {
      method: "POST",
      headers: {
        Authorization: Tokenwhatsap,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    var response = fetch(url, options_user)
      .then((response) => response.text())
      .then((data) => console.log(""));

    swal("Success", "pesan terkirim melalui whatsapp untuk " + nama, "success");
  }

  const simpanAdmin = async () => {
    const formData = new FormData();
    let dataKu = {};
    dataKu.desa = desa;
    dataKu.password = password;
    dataKu.username = removeExtraSpaces(username);
    dataKu.noHp = noHp;
    dataKu.status = status;
    dataKu.nama = removeExtraSpaces(nama);
    dataKu.idAdmin = 1;

    await axios({
      method: "post",
      url: USERS,
      timeout: API_TIMEOUT,
      data: dataKu,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          swal("Failed", "ada kesalahan pada server", "error");
        } else {
          //BERHASIL
          swal("Success", "User berhasil ditambahkan", "success");
          ambilUsers();
          kirimPesan(
            removeExtraSpaces(nama),
            noHp,
            removeExtraSpaces(username),
            password
          );
          onCloseModal();
        }
      })
      .catch((error) => {
        // ERROR
        swal("Failed", "Ada kesalahan pada server", "error");
      });
  };

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: "get",
      url: GET_DESA,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setListDesa(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilUsers = (id) => {
    axios({
      method: "get",
      url: USERS,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const onCloseModal = () => {
    setNama("");
    setUsername("");
    setStatus("");
    setPassword("");
    setNoHp("");
    setUid("");
    setUrl("");
    setGambar("");
    setSearchItem("");
    setDesa("");
    setModalOpen(false);
  };

  const onModalClose2 = () => {
    setNama("");
    setUid("");
    setModalOpen2(false);
  };

  const onModalClose3 = () => {
    setModalOpen3(false);
  };

  const handleDelete = async () => {
    const response = await axios.delete(
      USERS + "hapus/" + uid,

      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success === 1) {
      swal("Sukses", "Data Admin " + nama + " berhasil dihapus", "success");
      ambilUsers();

      onModalClose2();
    } else {
    }
  };

  const statusUser = (status) => {
    if (status == 1) {
      return <Badge color="primary">Super Admin</Badge>;
    } else if (status == 2) {
      return <Badge color="success">Admin</Badge>;
    } else if (status == 3) {
      return <Badge color="warning">User</Badge>;
    } else if (status == 4) {
      return <Badge color="danger">Suspended</Badge>;
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">Pengguna Ita Esa APP</CardTitle>
                {currentUser ? (
                  currentUser.status == "super admin" ? (
                    <Button color="danger" onClick={() => setModalOpen(true)}>
                      Tambah User
                    </Button>
                  ) : (
                    <div />
                  )
                ) : (
                  <div />
                )}
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>No HP</th>
                      <th>Desa</th>

                      <th className="text-center">Status</th>
                      <th>Aktifitas</th>
                      <th>Tanggal Terakhir Aktif</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelPerangkatDaerah &&
                      tabelPerangkatDaerah.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.nama}</td>
                          <td>{item.noHp}</td>

                          <td>{item.desa}</td>

                          <td className="text-center" style={{ width: 100 }}>
                            {statusUser(item.status)}
                          </td>
                          <td>
                            {item.page == "Logout" || !item.page ? (
                              <Badge>Tidak Aktive</Badge>
                            ) : (
                              <Badge color="success">Aktive</Badge>
                            )}
                          </td>
                          <td>
                            {item.page
                              ? formatDateTime(item.createdAt)
                              : "Belum Pernah Akses"}
                          </td>
                          <td>
                            {currentUser ? (
                              currentUser.status == "super admin" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div style={{ position: "relative" }}>
                                    <Button
                                      size="sm"
                                      color="success"
                                      style={{ marginRight: 5 }}
                                      onClick={() => {
                                        setUserId(item.id);
                                        setModalOpen3(true);
                                      }}
                                    >
                                      aktifitas
                                    </Button>
                                    <Badge
                                      style={{
                                        position: "absolute",
                                        left: -10,
                                        top: -10,
                                      }}
                                      color="primary"
                                    >
                                      {formatNumberToThousands(item.input)}
                                    </Badge>
                                  </div>
                                  <Button
                                    size="sm"
                                    color="primary"
                                    style={{ marginRight: 5 }}
                                  >
                                    edit
                                  </Button>
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() => {
                                      setNama(item.nama);
                                      setUid(item.id);
                                      setModalOpen2(true);
                                    }}
                                  >
                                    suspend
                                  </Button>
                                </div>
                              ) : (
                                <div />
                              )
                            ) : (
                              <div />
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="md"
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className="headline-2">{"Tambah User"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Nama
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    id="default-textarea"
                    placeholder="nama"
                    value={nama}
                    style={{ color: "black" }}
                    onChange={(e) => setNama(e.target.value)}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Email / Username
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    id="default-textarea"
                    placeholder="username"
                    style={{ color: "black" }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Nomor HP
                </Label>
                <Col md={9}>
                  <Input
                    type="number"
                    id="default-textarea"
                    placeholder="No HP"
                    style={{ color: "black" }}
                    value={noHp}
                    onChange={(e) => setNoHp(e.target.value)}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label
                  style={{ cursor: "pointer" }}
                  md={3}
                  for="normal-field"
                  className="text-md-right"
                  onClick={() => setPassword(generatePassword())}
                >
                  Create Password
                </Label>
                <Col md={9}>
                  <Input
                    type="text"
                    id="default-textarea"
                    placeholder="password"
                    style={{ color: "black" }}
                    value={password}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Peran
                </Label>
                <Col md={9}>
                  <Input
                    type="select"
                    id="normal-field"
                    style={{ color: "black" }}
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={""}>- pilih - </option>
                    <option value={1}>Super Admin</option>
                    <option value={2}>Admin </option>
                    <option value={3}>user </option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={3} for="normal-field" className="text-md-right">
                  Asal Desa
                </Label>
                <Col md={9}>
                  <Input
                    type="select"
                    id="normal-field"
                    value={desa}
                    style={{ color: "black" }}
                    onChange={(e) => setDesa(e.target.value)}
                  >
                    <option value={""}>-pilih-</option>
                    {listDesa.map((item) => (
                      <option value={item.desa}>{item.desa}</option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button
                className="btn-rounded"
                color="primary"
                size="sm"
                onClick={() => handleSimpan()}
              >
                {" "}
                {"Simpan"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          size="md"
          isOpen={modalOpen2}
          toggle={() => onModalClose2()}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => onModalClose2()}>
            <div>
              <div className="headline-2">Konfirmasi hapus akun</div>
            </div>
          </ModalHeader>
          <ModalBody>
            Apakah anda ingin menghapus akun atas nama {nama} ?
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => onModalClose2()}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button
                className="btn-rounded"
                color="primary"
                size="sm"
                onClick={handleDelete}
              >
                Hapus
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          size="md"
          isOpen={modalOpen3}
          toggle={() => onModalClose3()}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => onModalClose3()}>
            <div>
              <div className="headline-2">Daftar Aktifitas Akun</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <AktifitasUser userId={userId} />
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => onModalClose3()}
              >
                Close
              </Button>
              <div style={{ marginLeft: 5 }}></div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default UsersManek;
