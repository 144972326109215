export const API_MANEK = "https://aumafada.rotendaokab.go.id/api/";

export const API_ITAESA = "https://itaesa.net/api/";
//'http://localhost:4000/api/';

export const GET_DESA = API_ITAESA + "desa/";

export const GET_USER_AKTIVITY = API_ITAESA + "useraktivity/";
export const GET_PENDUKUNG_DESA = API_ITAESA + "dukungan/desa";
export const GET_PENDUKUNG_KECAMATAN = API_ITAESA + "dukungan/kecamatan";
export const GET_DPT_KECAMATAN = API_ITAESA + "dpt/kecamatan";

export const GET_AKTIFITAS_USER = API_ITAESA + "aktifitas/user/";

export const GET_PILKADA2018 = API_ITAESA + "pilkada2018";

export const GET_PILKADA2024 = API_ITAESA + "pilkada2024";
export const GET_PILKADA2024_TPS = API_ITAESA + "pilkada2024/tps";
export const POST_PILKADA2024_TPS = API_ITAESA + "pilkada2024/tps";

export const GET_PILKADA2018_TOTAL = API_ITAESA + "pilkada2018/total";
export const GET_PILKADA2024_TOTAL = API_ITAESA + "pilkada2024/total";

export const GET_PERDESA = API_ITAESA + "dukungan/perdesa";

export const GET_SAMPEL = API_ITAESA + "sampel";

export const POST_PENDUKUNG = API_ITAESA + "pendukung/desa";
export const USERS = API_ITAESA + "users/";
export const LOGIN = API_ITAESA + "users/login";

export const USERS_TOKEN = API_ITAESA + "users/token";

export const LOG_APP = API_ITAESA + "logapp/";

export const domain = "http://itaesa.net/dokumen/";

export const API_TIMEOUT = 120000;
